<template>
  <div class="aboutus" id="aboutus">
    <!--  -->
    <div class="one-bg-box">
      <div class="obb-box">
        <div class="obb-tit">山东路通石油</div>
        <div class="obb-txt">创新突破稳定品质</div>
        <div class="obb-txt">落实管理提高效率</div>
        <div class="obb-bottom-box">
          <div class="obb-bottom-b-icon obi-l"></div>
          <div class="obb-bottom-b-middle">用户满意是我们永恒的追求</div>
          <div class="obb-bottom-b-icon obi-r"></div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="part-building-box">
      <div class="pbb-tit-en">About us</div>
      <div class="pbb-tit">
        <div class="pbbt-line"></div>
        <div class="pbbt-tit">关于我们</div>
      </div>
      <div class="pbb-content">
        <div class="pbbc-top">
          山东路通石化有限公司（以下简称路通公司）是由山东省济邹公路有限公司
          （以下简称济邹公司）、中国石化销售股份有限公司山东石油分公司（以下简
          称中石化）、山东金鲁班集团有限公司（以下简称金鲁班）三家单位共同出资
          ，于2012年5月9日经省工商局批准设立的合资公司。公司注册资本为3000万
          元，其中：济邹公司出资1230万元，占41%；中石化出资1200万元，占40%
          ；金鲁班出资570万元，占19%。
          路通公司自2012年成立截止到目前，依托中石化的品牌效应和经营管理模式，
          路通公司旗下经营的加油（气）站已达到11座，分别为长深高速公路沿线的沂
          南服务区加油站（对站）、莒县停车区加油站（对站）、临沭服务区加油站
          （对站）、临沭停车区加油站（对站）、济徐高速汶上停车区加油站（对站）
          、枣庄峄州港水上服务区加油加气站。上述站点均经营效益良好。
        </div>
        <div class="pbbc-bottom">
          <div class="phone-box">
            <img src="../static/aboutusPhoneicon.png" class="pb-left" />
            <div class="pb-right">
              <div class="pbr-txt">联系电话</div>
              <div class="pbr-num">1111111111111</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-bottom></page-bottom>
  </div>
</template>
<script>
import PageBottom from "../components/PageBottom.vue";
export default {
  components: {
    PageBottom,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}
.aboutus {
  .one-bg-box {
    height: 400px;
    background-image: url("../static/aboutustopbg.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .obb-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .obb-tit {
        margin-top: 20px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #e40316;
        opacity: 1;
      }
      .obb-txt {
        margin-top: 10px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
      }
      .obb-bottom-box {
        display: flex;
        .obb-bottom-b-middle {
          padding: 0 5px;
          margin: 0 5px;
          display: flex;
          align-items: center;
          height: 30px;
          background-color: #ffffff;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
        .obb-bottom-b-icon {
          width: 60px;
          height: 30px;
          background-size: 100% 100%;
        }
        .obi-l {
          background-image: url("../static/homeiconleft.png");
        }
        .obi-r {
          background-image: url("../static/homeiconright.png");
        }
      }
    }
  }

  .part-building-box {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pbb-tit-en {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2e86a9;
      opacity: 1;
    }
    .pbb-tit {
      width: 600px;
      .pbbt-line {
        margin-top: 50px;
        width: 100%;
        border-bottom: 1px solid #000000;
      }
      .pbbt-tit {
        height: 40px;
        text-align: center;
        top: -20px;
        left: 200px;
        position: relative;
        width: 200px;
        background-color: #fff;
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #2e86a9;
        opacity: 1;
      }
    }
    .pbb-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 50px 300px;

      .pbbc-top {
        text-align: center;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: #333333;
        opacity: 1;
      }
      .pbbc-bottom {
        display: flex;
        flex-direction: row-reverse;
        .phone-box {
          display: flex;
          align-items: center;
          .pb-left {
            height: 36px;
            width: 36px;
            margin-right: 20px;
          }
          .pb-right {
            display: flex;
            flex-direction: column;
            align-items: center;
            .pbr-txt {
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              opacity: 1;
            }
            .pbr-num {
              margin-top: 20px;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>

